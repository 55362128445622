@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .container-small {
    @apply mx-auto max-w-3xl px-4 lg:px-0;
  }
  .container-large {
    @apply mx-auto max-w-7xl;
  }
}

::selection {
  @apply bg-hex-400 text-white;
}

[data-rich-colors="true"] [data-sonner-toast][data-type="success"],
[data-rich-colors="true"] [data-sonner-toast][data-type="success"] {
  @apply rounded-full !border-green-500/10 !bg-green-500/5 px-5 !text-green-500 backdrop-blur-xl backdrop-saturate-200;
}

[data-rich-colors="true"] [data-sonner-toast][data-type="error"],
[data-rich-colors="true"] [data-sonner-toast][data-type="error"] {
  @apply rounded-full !border-red-500/10 !bg-red-500/5 px-5 !text-red-500 backdrop-blur-xl backdrop-saturate-200;
}

.recharts-surface {
  @apply !overflow-visible;
}

.no-scrollbar::-webkit-scrollbar {
  @apply hidden;
}

.recharts-legend-item {
  @apply !cursor-pointer;
}
